import React, { useState, useEffect, useMemo } from 'react';
import ImageWithTextOnHover from '../ImageWithTextOnHover.tsx';
import styled from 'styled-components';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Styled components for layout
const NavigationWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    gap: 40px;
    justify-content: center;
`;

const NavButton = styled.button`
    background: none;
    border: none;
    color: #ffdd95;
    font-size: 2rem;
    cursor: pointer;

    &:disabled {
        color: #424b43;
    }
`;

const GalleryGrid = styled.div`
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
    }
`;

interface ImageSource {
    id: string;
    subtitle: string;
    imageUrl: string;
}

interface GalleryProps {
    sources: any[];
    itemsPerPage?: number;
}

const AllListingsGallery: React.FC<GalleryProps> = ({ sources, itemsPerPage }) => {
    const formattedSources = useMemo((): ImageSource[] => {
        return sources.map(source => ({
            id: source.sys.id,
            subtitle: source.subTitle,
            imageUrl: `${source.mainImage.url}?fm=avif&w=600`,
        }));
    }, [sources]);

    const [currentPage, setCurrentPage] = useState(0);
    const [currentSources, setCurrentSources] = useState<ImageSource[]>([]);

    const pageLimit = itemsPerPage || formattedSources.length;
    const totalPages = Math.ceil(formattedSources.length / pageLimit);

    useEffect(() => {
        const startIndex = currentPage * pageLimit;
        const endIndex = startIndex + pageLimit;
        setCurrentSources(formattedSources.slice(startIndex, endIndex));
    }, [currentPage, pageLimit, formattedSources]);

    const renderGalleryItems = useMemo(() => currentSources.map(source => (
        <ImageWithTextOnHover
            key={source.id}
            id={source.id}
            imgUrl={source.imageUrl}
            text={source.subtitle}
        />
    )), [currentSources]);

    return (
        <div className='galleryComponent'>
            <GalleryGrid>
                {renderGalleryItems}
            </GalleryGrid>
            {totalPages > 1 && (
                <NavigationWrapper>
                    <NavButton
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))}
                        disabled={currentPage === 0}
                    >
                        <KeyboardArrowLeftIcon />
                    </NavButton>
                    <NavButton
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages - 1))}
                        disabled={currentPage === totalPages - 1}
                    >
                        <KeyboardArrowRightIcon />
                    </NavButton>
                </NavigationWrapper>
            )}
        </div>
    );
}

export default AllListingsGallery;
