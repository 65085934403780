import React from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components"

const HeaderText = styled.h1`
    font-weight: 600;
    font-size: 14px;
    color: var(--text-color-secondary);
    text-transform: uppercase;
    margin-bottom: 5px;
`;
interface props {
    imgUrl: string;
    text: string;
    id: string;
}
function ImageWithTextOnHover({ imgUrl, text, id }: props) {
    return (
        <Link to={"project/" + id}>
            <div className='textOnHover'>
                <div>
                    <HeaderText>{text}</HeaderText>
                </div>
                <img
                    src={imgUrl}
                    alt='click to see project details'
                    loading="lazy"
                    decoding="async"
                    
                />
            </div>
        </Link>
    );
}

export default ImageWithTextOnHover;
