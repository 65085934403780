import React, { useState, useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { fetchBlogPost, fetchAsset } from '../Utils/Api.tsx';
import { useParams } from 'react-router-dom';
import { BLOCKS } from '@contentful/rich-text-types';
import styled from 'styled-components';
import LoadingSpinner from '../Components/LoadingSpinner.tsx';
import NotFoundPage from '../Components/NotFoundPage.tsx';

const Wrapper = styled.div`
    padding: 20px 20px 90px 20px;
    > img {
        width: 100%;
        max-height: 500px;
        margin-bottom: 20px;
        object-fit: cover;
    }
    h1 {
        margin-bottom: 20px;
    }
    h2, h3, h4, h5, h6 {
        margin-bottom: 10px;
        margin-top: 40px;
    }
    p {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.5px;
    }
    ul, ol {
        margin-bottom: 10px;
        padding-left: 40px;
    }
    a {
        color: #94c8ff;
    }
`;

const ImageAsset = styled.div`
    text-align: center;
    margin: 20px 0;
    img {
        max-width: 100%;
        max-height: 500px;
        margin: auto;
        display: block;
        object-fit: cover;
    }
    p {
        font-size: 14px;
        color: #8b8b8b;
        padding: 5px 0;
    }
`;

const fetchBlogPostData = async (id: string, setBlogPost: Function, setEmbeddedAssets: Function) => {
    if (!id) {
        console.error("Blog post ID is undefined.");
        return;
    }
    try {
        const data = await fetchBlogPost(id);
        setBlogPost(data.blogPost);

        const assetMap: Record<string, any> = {};
        const embeddedAssetNodes = data.blogPost.content.json.content.filter(
            (node: any) => node.nodeType === BLOCKS.EMBEDDED_ASSET
        );

        for (const node of embeddedAssetNodes) {
            const assetId = node.data.target.sys.id;
            const asset = await fetchAsset(assetId);
            assetMap[assetId] = asset;
        }

        setEmbeddedAssets(assetMap);
    } catch (error) {
        console.error("Error fetching blog post data:", error);
    }
};

const renderEmbeddedAsset = (node: any, embeddedAssets: Record<string, any>) => {
    const assetId = node.data.target.sys.id;
    const assetData = embeddedAssets[assetId];

    if (!assetData) return <LoadingSpinner />;

    return (
        <ImageAsset>
            <img
                src={`${assetData.asset.url}?fm=avif`}
                alt={assetData.asset.title || 'Asset'}
                loading="lazy"
                decoding="async"
            />
            <p>{assetData.asset.title}</p>
        </ImageAsset>
    );
};

const BlogPost: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [blogPost, setBlogPost] = useState<any>(null);
    const [embeddedAssets, setEmbeddedAssets] = useState<Record<string, any>>({});
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (id) {
            fetchBlogPostData(id, setBlogPost, setEmbeddedAssets)
                .finally(() => setLoading(false));
        }
    }, [id]);

    const renderOptions = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => renderEmbeddedAsset(node, embeddedAssets),
        },
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!blogPost) {
        return <NotFoundPage />;
    }

    return (
        <Wrapper className="blog-post">
            <h1>{blogPost.title}</h1>
            <img
                src={`${blogPost.mainImage.url}?fm=avif`}
                alt={blogPost.title}
                decoding="async"
            />
            {documentToReactComponents(blogPost.content.json, renderOptions)}
        </Wrapper>
    );
};

export default BlogPost;