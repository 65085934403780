import React, { useState, useEffect, useCallback } from 'react';
import uuid4 from 'uuid4';
import ThumbnailGallery from './ThumbnailGallery.tsx';
import FocusedImage from './FocusedImage.tsx';
import ProjectInfo from './ProjectInfo.tsx';
import styled from 'styled-components';

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

interface ImageElement {
    caption: string;
    thumb: string;
    big: string;
    contentType: string;
}

interface Listing {
    _id: string;
    uuid: string;
    name: string;
    dimentions: string;
    price: number;
    imageUrl: string;
}

const ListingImages: React.FC<{ data: any }> = ({ data }) => {
    const [focusedImage, setFocusedImage] = useState<ImageElement | null>(null);
    const [gallery, setGallery] = useState<ImageElement[]>([]);

    const createImageElement = (imageObj: any): ImageElement => ({
        caption: imageObj.description,
        thumb: `${imageObj.url}?fm=avif&w=150`,
        big: `${imageObj.url}?fm=avif&w=900`,
        contentType: imageObj.contentType,
    });

    const generateListings = useCallback((data: any): Listing[] => {
        return data.availableDimentionsCollection.items.map((item: any) => ({
            _id: item._id,
            uuid: uuid4(),
            dimensions: item.dimentions,
            price: item.price,
            name: data.title,
            imageUrl: data.mainImage.url,
        }));
    }, []);

    const navigateImage = useCallback((direction: 'next' | 'prev') => {
        if (!focusedImage) return;
        const currentIndex = gallery.indexOf(focusedImage);
        const newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
        if (newIndex >= 0 && newIndex < gallery.length) {
            setFocusedImage(gallery[newIndex]);
        }
    }, [focusedImage, gallery]);

    useEffect(() => {
        if (data.mainImage) {
            const mainImage = createImageElement(data.mainImage);
            setFocusedImage(mainImage);

            const galleryImages = data.galleryCollection?.items.map(createImageElement) || [];
            setGallery([mainImage, ...galleryImages]);

            // Pre-load images
            [mainImage, ...galleryImages].forEach(image => {
                const img = new Image();
                img.src = image.big;
            });
        }
    }, [data]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.code === 'ArrowLeft') navigateImage('prev');
            if (event.code === 'ArrowRight') navigateImage('next');
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [navigateImage]);

    if (!focusedImage) return <div className='loading'>Loading...</div>;

    return (
        <div className='modalData'>
            <FocusedImage focusedImage={focusedImage} navigateImage={navigateImage} gallery={gallery} />
            <InfoWrapper>
                <ProjectInfo data={data} generateListings={generateListings} />
                <ThumbnailGallery gallery={gallery} setFocusedImage={setFocusedImage} />
            </InfoWrapper>
        </div>
    );
};

export default ListingImages;