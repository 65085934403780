import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFoundPage.css'; // Assuming you have a CSS file for styling

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState<number>(5); // 8 seconds countdown

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(timer);
                    navigate('/');
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [navigate]);

    return (
        <div className="not-found-container">
            <h1>404 - Page Not Found</h1>
            <p>We're sorry, but the page you are looking for doesn't exist.</p>
            <p>You will be redirected to the homepage in <span>{countdown}</span> seconds.</p>
        </div>
    );
};

export default NotFoundPage; 