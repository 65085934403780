import React from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface ImageElement {
    caption: string;
    thumb: string;
    big: string;
    contentType: string;
}

const FocusedImage: React.FC<{ focusedImage: ImageElement, navigateImage: (direction: 'next' | 'prev') => void, gallery: ImageElement[] }> = ({ focusedImage, navigateImage, gallery }) => (
        <div className='focusedContainer'>
            <div>
                {focusedImage.contentType.includes('video') ? (
                    <video controls>
                        <source src={focusedImage.big} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <InnerImageZoom src={focusedImage.big} alt='focused' />
                )}
                <div className='navigateModal'>
                    <button onClick={() => navigateImage('prev')} disabled={gallery.indexOf(focusedImage) === 0}>
                        <ArrowBackIosIcon />
                    </button>
                    <button onClick={() => navigateImage('next')} disabled={gallery.indexOf(focusedImage) === gallery.length - 1}>
                        <ArrowForwardIosIcon/>
                    </button>
                </div>
            </div>
            <small>{focusedImage.caption}</small>
    </div>
);

export default FocusedImage; 