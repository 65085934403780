import React from 'react';
import ListingEntry from '../Components/Listings/ListingEntry.tsx';
import { useParams } from 'react-router-dom';

const Project = () => {
    const { id } = useParams<{ id: string }>();

    if (!id) {
        console.error("Project ID is undefined.");
        return null; 
    }

    return (
        <>
            <ListingEntry id={id} />
        </>
    );
}

export default Project;