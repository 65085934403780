import React from 'react';
import CvEntry from './CvEntry';

function CvView({
    bio,
    languages,
    cvEntries
}) {

    const { country, education, email, name, phone, born, biography, profilePicture } = bio;

    const categories = cvEntries.reduce((acc, item) => {
        if (!acc[item['category']]) {
            acc[item['category']] = [];
        }
        acc[item['category']].push(item);
        return acc;
    }, {});

    const cvEntryElements = Object.entries(categories).map(([categoryTitle, items], index) => (
        <div className='cvEntry' key={index}>
            <h2>{categoryTitle}</h2>
            <CvEntry data={items} />
        </div>
    ));

    const languageElements = languages.map((item, index) => (
        <div key={index}>
            <p>{item['language']}</p>
        </div>
    ));

    return (
        <div className='cv'>
            <div className='cvLeftCol'>
                <div className='personalInfo'>
                    <img
                        src={`${profilePicture?.url}?fm=avif`}
                        alt='portrait'
                        loading="lazy"
                        decoding="async"
                    />
                    <h2>{name}</h2>
                    <p>{education}</p>
                    <div>
                        <p><strong>E-mail: </strong> {email}</p>
                        <p><strong>Phone: </strong> {phone}</p>
                        <p><strong>Nationality: </strong> {country}</p>
                        <p><strong>Born: </strong> {born}</p>
                    </div>
                </div>
                <div>
                    <h2>Languages</h2>
                    {languageElements}
                </div>
            </div>
            <div className='cvRightCol'>
                <p>{biography}</p>
                {cvEntryElements}
            </div>
        </div>
    );
}

export default CvView;
