import React from 'react';
import PlaceOrder from './PlaceOrder.tsx';

interface Listing {
    _id: string;
    uuid: string;
    name: string;
    dimentions: string;
    price: number;
    imageUrl: string;
}

const ProjectInfo: React.FC<{ data: any, generateListings: (data: any) => Listing[] }> = ({ data, generateListings }) => (
    <div className='projectInfo'>
        {data.title && <h2>{data.title}</h2>}
        {data.availableForSale && <PlaceOrder Listings={generateListings(data)} />}
        {data.description && <p>{data.description}</p>}
        {data.footnote && <small className='footNote'>{data.footnote}</small>}
        {!data.availableForSale && <h5>* Prints are currently out of stock</h5>}
    </div>
);

export default ProjectInfo; 