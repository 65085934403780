import React, { useEffect, useState } from 'react';
import ListingImages from './ListingContent.tsx';
import { fetchGalleryEntry } from '../../Utils/Api.tsx';
import LoadingSpinner from '../LoadingSpinner.tsx';
import NotFoundPage from '../NotFoundPage.tsx';

interface ListingEntryProps {
    id: string;
}

const ListingEntry: React.FC<ListingEntryProps> = ({ id }) => {
    const [galleryData, setGalleryData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchGalleryEntry(id);
                if (result?.galleryEntries) {
                    setGalleryData(result.galleryEntries);
                } else {
                    console.error("No gallery entries found for the given ID.");
                }
            } catch (error) {
                console.error("Error fetching gallery data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!galleryData) {
        return <NotFoundPage />;
    }

    return <ListingImages data={galleryData} />;
}

export default ListingEntry;