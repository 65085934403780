import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useCart } from "../../Context/CartContext.tsx";
import Snackbar from '@mui/material/Snackbar';

const StyledButton = styled(Button)`
    display: flex;
    gap: 8px;
    font-weight: 600!important;
    && {
        background-color: #ffdd95; 
        color: #000000;
    
        &:hover {
            background-color: #ffbf34;
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
    max-width: 300px;
`;

const PriceInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const PriceText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;

    & h2 {
        font-size: 22px;
        font-weight: 600;
    }

    & p {
        padding: 0;
    }
`;

interface Listing {
    _id: string;
    uuid: string;
    name: string;
    dimentions: string;
    price: number;
    imageUrl: string;
}

interface Props {
    Listings: Listing[];
}

const PlaceOrder: React.FC<Props> = ({ Listings: data }) => {
    const { addItem } = useCart();

    const [selectedListing, setSelectedListing] = React.useState<Listing | null>(data.length > 0 ? data[0] : null);
    const [snackBarMessage, setSnackBarMessage] = React.useState<string>("");

    const handleChange = (event: SelectChangeEvent) => {
        // Find the listing based on the selected _id
        const listing = data.find(item => item._id === event.target.value);
        if (listing) {
            setSelectedListing(listing);

        }
    };

    const handleSubmission = () => {
        if (selectedListing) {
            setSnackBarMessage("Item added to cart");
            addItem(selectedListing);
        }
    }

    if (data.length === 0) {
        return null;
    }

    return (
        <>
            <Snackbar open={snackBarMessage !== ""} autoHideDuration={2000} onClose={() => setSnackBarMessage("")} message={snackBarMessage} />
            <Wrapper>
                <FormControl fullWidth>
                    <InputLabel>dimentions</InputLabel>
                    <Select
                        label="dimentions"
                        value={selectedListing?._id || ""}
                        onChange={handleChange}
                    >
                        {data.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                                {item.dimentions + " - " + item.price + ",-"}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <PriceInfo>
                    <PriceText>
                        <h2>{selectedListing?.price},- </h2>
                        <p>inkl. mva</p>
                    </PriceText>

                    <StyledButton variant="contained" onClick={handleSubmission} disabled={!selectedListing}>
                        <ShoppingCartIcon />
                    </StyledButton>
                </PriceInfo>
            </Wrapper>
        </>
    );
};

export default PlaceOrder;
