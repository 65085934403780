import React, { useState, useEffect } from 'react';
import { Typography, IconButton } from '@mui/material';
import styled from 'styled-components';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Snackbar from '@mui/material/Snackbar';
import CartTable from './CartTable.tsx';
import CartShipping from './CartShipping.tsx';
import { useCart } from "../../../Context/CartContext.tsx";
import { motion, AnimatePresence } from 'framer-motion';

const ToCheckoutWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 50px;
`;

const Wrapper = styled(motion.div)`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 650px;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #1a1b18;
    padding: 20px;
    border-radius: 15px;
    z-index: 1000;

    @media (max-width: 600px) {
        width: 100%;
        border-radius: 15px 15px 0 0;
        bottom: 0;
        right: 0;
        margin: 0;
    }
`;

const BubbleButton = styled.div`
    position: fixed;
    bottom: 95px;
    right: 30px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: background-color 0.2s;

    &:hover {
        background-color: #ffbf34;
    }

    & svg {
        color: #443026;
    }
`;

const NotificationCircle = styled.div`
    position: fixed;
    width: 15px;
    height: 15px;
    bottom: 130px;
    right: 30px;
    background-color: #c90000;
    z-index: 100;
    border-radius: 50%;
    box-shadow: 0 0 5px #c90000;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`;

const CartBubble: React.FC = () => {
    const { items, clearCart } = useCart();
    const [open, setOpen] = useState(false);
    const [showContent, setShowContent] = useState(false); // Controls the rendering
    const [isInCheckout, setIsInCheckout] = useState(false);
    const [result, setResult] = useState("");
    const [orderInfo, setOrderInfo] = useState<object | null>(null);
    const [formValues, setFormValues] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
        city: '',
        address: '',
        postalCode: '',
    });

    useEffect(() => {
        if (open) {
            setShowContent(true);
        }
    }, [open]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const evensKey = "04151b70-3170-4927-bbf0-f519bc368581";

        const dataWithAccessKey = {
            ...formValues,
            ...orderInfo,
            access_key: evensKey,
        };
        const response = await fetch('https://api.web3forms.com/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...dataWithAccessKey
            }),
        });
        const result = await response.json();

        if (result.success) {
            setResult("Your order has been submitted successfully. I will be in touch with you shortly to confirm the details.");
            clearCart();
        } else {
            setResult("There was an error submitting your order. Please try again.");
        }

        closeChat();
    };

    const closeChat = () => {
        setOpen(false);
        setTimeout(() => setShowContent(false), 200); // Delay unmount after exit animation
    };

    const variants = {
        hidden: { y: 600 },
        visible: { y: 0 },
        exit: { y: 600 },
    };

    return (
        <>
            {items.length > 0 && <NotificationCircle />}

            <Snackbar
                open={result !== ""}
                autoHideDuration={4000}
                onClose={() => setResult("")}
                message={result}
            />

            <AnimatePresence>
                {showContent && (
                    <Wrapper
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={variants}
                        transition={{ duration: 0.2 }}
                    >
                        <Header>
                            <Typography variant="h6" gutterBottom>
                                Your cart
                            </Typography>
                            <IconButton onClick={closeChat}>
                                <ExpandMore />
                            </IconButton>
                        </Header>

                        {items.length === 0 && (
                            <Typography variant="body1" gutterBottom>
                                Your cart is empty. Explore the shop to find the selection of quality prints that are available for purchase.
                            </Typography>
                        )}

                        {items.length > 0 && !isInCheckout && (
                            <ToCheckoutWrapper>
                                <CartTable
                                    setOrderInfo={setOrderInfo}
                                    setIsInCheckout={setIsInCheckout}
                                />
                            </ToCheckoutWrapper>
                        )}

                        {items.length > 0 && isInCheckout && (
                            <CartShipping
                                formValues={formValues}
                                setFormValues={setFormValues}
                                handleSubmit={handleSubmit}
                                setIsInCheckout={setIsInCheckout}
                            />
                        )}
                    </Wrapper>
                )}
            </AnimatePresence>

            {!open && (
                <BubbleButton>
                    <IconButton size="large" onClick={() => setOpen(true)}>
                        <ShoppingBagIcon />
                    </IconButton>
                </BubbleButton>
            )}
        </>
    );
};

export default CartBubble;
