import React, { useState, useEffect, useCallback } from 'react';
import AllListingsGallery from '../Components/Listings/AllListingsGallery.tsx';
import { fetchGalleryCollection } from '../Utils/Api.tsx';
import LoadingSpinner from '../Components/LoadingSpinner.tsx';

const Gallery = () => {
    const [restData, setRestData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadGalleryData = useCallback(async () => {
        try {
            const result = await fetchGalleryCollection();
            setRestData(result?.galleryEntriesCollection.items);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        loadGalleryData();
    }, [loadGalleryData]);

    if (loading) return <LoadingSpinner />;
    if (error) return <div>Error loading gallery data.</div>;
    if (!restData) return <div>No gallery data available.</div>;

    return <AllListingsGallery itemsPerPage={6} sources={restData} />;
}

export default React.memo(Gallery);
