import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Typography,
    IconButton,
    Snackbar,
} from '@mui/material';
import styled from 'styled-components';
import Grid from '@mui/material/Grid2';
import SendIcon from '@mui/icons-material/Send';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { motion, AnimatePresence } from 'framer-motion';

const Wrapper = styled(motion.div)`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 350px;
    background-color: #1a1b18;
    padding: 15px;
    border-radius: 15px;
    z-index: 1000;

    @media (max-width: 600px) {
        width: 100%;
        border-radius: 15px 15px 0 0;
        bottom: 0;
        right: 0;
        margin: 0;
    }
`;

const BubbleButton = styled.div`
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #ffdd95;
    border-radius: 50%;
    transition: background-color 0.2s;

    &:hover {
        background-color: #ffbf34;
    }

    & svg {
        color: #443026;
    }
`;

const StyledTextField = styled(TextField)``;

const StyledButton = styled(Button)`
    display: flex;
    gap: 8px;
    font-weight: 600 !important;
    && {
        background-color: #ffdd95;
        color: #000000;

        &:hover {
            background-color: #ffbf34;
        }
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`;

const ChatBubble: React.FC = () => {
    const [formValues, setFormValues] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });
    const [errors, setErrors] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });
    const [open, setOpen] = useState(false);
    const [result, setResult] = useState('');
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        if (open) {
            setShowContent(true);
        }
    }, [open]);

    const validateField = (name: string, value: string) => {
        let error = '';

        if (name === 'name' && !value) {
            error = 'Name is required';
        }
        if (name === 'phone' && value && !/^[0-9]{8,10}$/.test(value)) {
            error = 'invalid phone number';
        }
        if (name === 'email' && (!value || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))) {
            error = 'invalid email';
        }
        if (name === 'message' && !value) {
            error = 'Message is required';
        }

        return error;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validateField(name, value),
        }));
    };

    const validateForm = () => {
        const newErrors = Object.keys(formValues).reduce((acc, field) => {
            const error = validateField(field, formValues[field]);
            if (error) acc[field] = error;
            return acc;
        }, {} as typeof errors);

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => error === '');
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const isFormValid = validateForm();
        if (isFormValid) {
            const evensKey = '04151b70-3170-4927-bbf0-f519bc368581';

            const dataWithAccessKey = {
                ...formValues,
                access_key: evensKey,
            };
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...dataWithAccessKey,
                }),
            });
            const result = await response.json();

            if (result.success) {
                setResult('Form submitted successfully!');
            } else {
                setResult('There was an error submitting the form. Please try again later.');
            }

            closeChat();
        }
    };

    const closeChat = () => {
        setOpen(false);
        setTimeout(() => setShowContent(false), 200); // Delay unmount after exit animation
        setFormValues({
            name: '',
            phone: '',
            email: '',
            message: '',
        });
        setErrors({
            name: '',
            phone: '',
            email: '',
            message: '',
        });
    };

    const variants = {
        hidden: { y: 600 },
        visible: { y: 0 },
        exit: { y: 600 },
    };

    return (
        <>
            <Snackbar
                open={result !== ''}
                autoHideDuration={4000}
                onClose={() => setResult('')}
                message={result}
            />

            <AnimatePresence>
                {showContent && (
                    <Wrapper
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={variants}
                        transition={{ duration: 0.2 }}
                    >
                        <Header>
                            <Typography variant="h6" gutterBottom>
                                Contact me
                            </Typography>
                            <IconButton onClick={closeChat}>
                                <ExpandMore />
                            </IconButton>
                        </Header>

                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container spacing={2}>
                                <Grid size={12}>
                                    <StyledTextField
                                        required
                                        name="name"
                                        label="Name"
                                        fullWidth
                                        variant="outlined"
                                        value={formValues.name}
                                        onChange={handleInputChange}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <StyledTextField
                                        name="phone"
                                        label="Phone number (8 digits)"
                                        fullWidth
                                        variant="outlined"
                                        value={formValues.phone}
                                        onChange={handleInputChange}
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <StyledTextField
                                        required
                                        name="email"
                                        label="E-mail"
                                        fullWidth
                                        variant="outlined"
                                        value={formValues.email}
                                        onChange={handleInputChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <StyledTextField
                                        required
                                        name="message"
                                        label="Message"
                                        multiline
                                        rows={6}
                                        fullWidth
                                        variant="outlined"
                                        value={formValues.message}
                                        onChange={handleInputChange}
                                        error={!!errors.message}
                                        helperText={errors.message}
                                    />
                                </Grid>
                                <Grid size={5.5} offset={6.5}>
                                    <StyledButton type="submit" variant="contained" fullWidth>
                                        Send
                                        <SendIcon fontSize="small" />
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Wrapper>
                )}
            </AnimatePresence>

            {!open && (
                <BubbleButton>
                    <IconButton size="large" onClick={() => setOpen(true)}>
                        <ChatBubbleIcon />
                    </IconButton>
                </BubbleButton>
            )}
        </>
    );
};

export default ChatBubble;
