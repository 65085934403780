import React from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';

const ThumbImage = styled.img`
    object-fit: cover;
    width: 100%;    
    height: 100%;
`;

interface ImageElement {
    caption: string;
    thumb: string;
    big: string;
    contentType: string;
}

const ThumbnailGallery: React.FC<{ gallery: ImageElement[], setFocusedImage: (image: ImageElement) => void }> = ({ gallery, setFocusedImage }) => (
    <div className='previews'>
        {gallery.map((val, key) => (
            <LazyLoad key={key} height={65}>
                <ThumbImage
                    onClick={() => setFocusedImage(val)}
                    src={val.contentType.includes('image') ? val.thumb : '/path/to/playImage.png'}
                    alt={val.caption}
                />
            </LazyLoad>
        ))}
    </div>
);

export default ThumbnailGallery; 